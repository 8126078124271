<template>
    <div class="greening-cover cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <h4 class="text-white mx-5 mt-5 mb-4">Озеленяване</h4>
                    <p class="text-white mx-5 my-4">
                        Нашите квалифицирани градинари ще се погрижат за Вашия двор и градина, за да може Вие да се насладите на спокойствието и красотата им.
                        <br><br><br><br>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "GreeningCover"
    }
</script>