<template>
    <div class="greening">
    <GreeningCover />
    <div class="container mt-5 px-2">
        <h3>Вътрешно и външно озеленяване</h3>
        <p>
            – Проектиране и изграждане на зелени площи;<br><br>
            – Абонаментна поддръжка на зелени площи от професионален градинар<br><br>
            – Доставка и поддръжка на интериорни растения за Вашия дом или офис;<br><br>
            - Вътрешно озеленяване на бизнес сгради, офиси и работни помещения<br><br>
            - Външно озеленяване на дворни пространства и други площи<br><br>
            - Поддръжка на зелени площи<br><br>
            - Подрязване на храсти<br><br>
            - Косене на трева/ и извозване на остатъчния материал/<br><br>
            - Почистване на дворове от храсти и други ненужни неща.<br><br>
        </p>
    </div>
    <EmailContact />
</div>
</template>

<script>
    import GreeningCover from './includes/GreeningCover'
    import EmailContact from '../contacts/EmailContact'

    export default {
        name: "Greening",
        components: {
            GreeningCover,
            EmailContact
        }
    }
</script>